import React, { useEffect, useState } from "react";
import { BiLogOutCircle } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import Button from "../components/Button";
import { getPaymentsStatus } from "../services/auth-services";


const Header = ({ userData }) => {
  const tokenData = JSON.parse(localStorage.getItem("token"))
  const [paymentStatus, setPaymentStatus] = useState(false)
  useEffect(() => {
    if (userData && !userData?.is_staff) {
      getPaymentsStatus(tokenData).then((res) => {
        setPaymentStatus(res.data.payment_status)
      }).catch((error) => {
        console.log('error: ', error);
      })
    }
    // eslint-disable-next-line 
  }, [userData])
  return (
    <div className="sticky top-0 z-30">
      <div className=""></div>
      <div className="bg-primary backdrop-blur-xl !z-50">
        <div className="flex-col flex">
          <div className="w-full border-b-2 border-gray-200 px-5 md:px-10">
            <div className="bg- h-16 flex !justify-between  gap-3 items-center mx-auto px- ">

              <div>
                <h1 className="font-bold text-white text-lg text-center">
                  MS FireChiefs
                </h1>
              </div>

              <div className="flex  items-center gap-3">
                <Button to={"/membership-form"} text={"Membership form"} customClass={'!bg-transparent border-b rounded-none px-0 border-white mr-3 text-white border-0 hover:text-white transition-all !text-xs'} />
                <Button to={"/payment-summary"} text={"Paid"} customClass={'!bg-transparent border-b rounded-none px-0 border-white  text-white border-0 hover:text-white transition-all !text-xs'} />
              </div>

              <div className=" justify-end items-center gap-2 flex">

                <div className="right-nav flex items-center">
                  <Button
                    customClass={`hover:!scale-105 !transition-all !duration-300 !gap-1 !font-semibold !rounded-md ${paymentStatus ? "!text-white !bg-lime-600" : "!text-white !bg-primary"}  !text-xs !cursor-pointer !whitespace-nowrap  !py-2 !px-2`}
                  >
                    {paymentStatus ? 'Membership Active' : 'Membership Inactive'}
                  </Button>
                </div>


                <div className="right-nav flex items-center">
                  <Button
                    to={'account-setting'}
                    customClass="hover:!scale-105 !transition-all !duration-300 !gap-1 !font-semibold !rounded-md !text-bluePrimary !text-xs !cursor-pointer !whitespace-nowrap  !py-1.5 !px-2"
                  >

                    <CgProfile className="!text-bluePrimary" size={20} />Profile
                  </Button>
                </div>

                <div className="right-nav flex items-center">
                  <Button
                    onClick={() => {
                      localStorage.clear()
                      window.location.href = "/signin"
                    }}
                    customClass="hover:!scale-105 !transition-all !duration-300 !gap-1 !font-semibold !rounded-md !text-primary !text-xs !cursor-pointer !whitespace-nowrap  !py-1.5 !px-2"
                  >
                    <BiLogOutCircle className="!text-primary" size={20} />Log Out
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
