import axios from 'axios';


// const auth_url = `http://40.124.181.246/api/accounts`;
// const data_url = `http://40.124.181.246/api/members`;
// const payment_url = `http://40.124.181.246/api/payments`;


const auth_url = `https://registration.msfirechiefs.org/api/accounts`;
const data_url = `https://registration.msfirechiefs.org/api/members`;
const payment_url = `https://registration.msfirechiefs.org/api/payments`;


// const auth_url = `http://192.168.1.2:8080/api/accounts`;
// const data_url = `http://192.168.1.2:8080/api/members`;
// const payment_url = `http://192.168.1.2:8080/api/payments`;

// const auth_url = `http://137.59.224.25:8084/accounts`;
// const data_url = `http://137.59.224.25:8084/members`;
// const payment_url = `http://137.59.224.25:8084/payments`;

// auth screens api's
export function LogIn(data) {
    return axios({
        method: "POST",
        url: `${auth_url}/login/`,
        data,
    });
}
export function signUp(data) {
    return axios({
        method: "POST",
        url: `${auth_url}/signup/`,
        data,
    });
}
export function resetPassword(data) {
    return axios({
        method: "POST",
        url: `${auth_url}/password_reset/`,
        data,
    });
}
export function confirmPassword(data, url) {
    return axios({
        method: "POST",
        url: `${auth_url}/password_reset/confirm/${url}/`,
        data,
    });
}
export function changePassword(token, data) {
    return axios({
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${auth_url}/change_password/`,
        data,
    });
}
export function CreateManager(data, token) {
    return axios({
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${data_url}/membershiproster_public/create/`,
        data,
    });
}

export function getMemberData(token) {
    return axios({
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${data_url}/membershiproster_public/member/`,
    });
}
export function getEventInfo() {
    return axios({
        method: "GET",
        url: `${payment_url}/event_registration/`,
    });
}
export function postEventInfo(data) {
    return axios({
        method: "POST",
        url: `${payment_url}/event_registration/`,
        data
    });
}
export function updateMemberData(data, token) {
    return axios({
        method: "PUT",
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${data_url}/membershiproster_public/member/update/`,
        data
    });
}
export function getPayments(data, token) {
    return axios({
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${payment_url}/create_payment/`,
        data
    });
}
export function CreateInvoice(data, token) {
    return axios({
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${payment_url}/create_invoice/`,
        data
    });
}
// payments/create_invoice
export function getPaymentsSummary(token) {
    return axios({
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${payment_url}/payment_summary/`,
    });
}
export function getPaymentsStatus(token) {
    return axios({
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${payment_url}/payment_status/`,
    });
}
export function getMembersData(filters, token, checkValue) {
    const queryString = filters.map(filter => {
        if (filter.start_date && filter.end_date) {
            return `${filter.column}__gte=${filter.start_date}&${filter.column}__lte=${filter.end_date}`;
        } else if (filter.query !== "") {
            return `${filter.column}__${filter.type}=${filter.query}`;
        }
        return '';
    }).filter(Boolean).join('&');
    return axios({
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${data_url}/membershiproster/?${queryString}${checkValue?.life_time === 1 ? `&lifetime__icontains=1` : ``}${checkValue?.committee_member === 1 ? `&committee_member__icontains=1` : ``}${checkValue?.executive_board === 1 ? `&executive_board__icontains=1` : ``}`
    });
}

export function getUserMembersData(token) {
    return axios({
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${data_url}/membershiproster/`
    });

} export function getDepartments(token) {
    return axios({
        method: 'GET',
        headers: token ? {
            Authorization: `Bearer ${token}`,
        } : null,
        url: `${data_url}/department_list/`
    });
}
export function getPublicMembersData(filters, token) {
    const queryString = filters.map(filter => {
        if (filter.start_date && filter.end_date) {
            // If start_date and end_date are present, concatenate them as the query
            return `${filter.column}__gte=${filter.start_date}&${filter.column}__lte=${filter.end_date}`;
        } else if (filter.query !== "") {
            // If there's a query, use it
            return `${filter.column}__${filter.type}=${filter.query}`;
        }
        // If there's no query or dates, return an empty string
        return '';
    }).filter(Boolean).join('&'); // Filter out any empty strings and join with '&'
    return axios({
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${data_url}/membershiproster_public/?${queryString}`
    });
}

export function getPublicMember(token) {
    return axios({
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${data_url}/membershiproster_public/`
    });
}
export function checkValidateInvoice(token, data) {
    return axios({
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${payment_url}/validate_invoice/`,
        data
    });
}
export function getSingleDepartment(token, id) {
    return axios({
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${data_url}/department/${id}`
    });
}

export function editSingleMemberDepartment(token, id, data) {
    return axios({
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${data_url}/department/${id}/`,
        data
    });
}